import { useRouter } from "next/router";
import React, { useEffect, useRef, useState } from "react";
import Link from "next/link";
import { useAuth } from "components/auth/hooks/useAuth";
import SearchBar from "@/components/ui/SearchBar/SearchBar";
import { useApp } from "@/components/context/AppContext/AppContext";
import { useTranslation } from "next-i18next";
// BOOTSTRAP.
import Dropdown from "react-bootstrap/Dropdown";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
// ICONS
import { ChevronDownIcon, ChevronUpIcon } from "@/public/icons/icons";
// COMPONENTS
import CustomDropdownToggle from "@/components/elements/AccordionsToggles/CustomDropdownToggle";
// STYLE
import s from "./MenuLinks.module.scss";
import fallbackTranslation from "@/lib/dbTranslations";

//TODO:: Vertical divider should be hide it is the last item of the row (or the first one)
export default function MenuLinks({ setExpanded }) {
  const auth = useAuth();
  const app = useApp();
  const router = useRouter();

  const { t } = useTranslation(["header", "common", "db-translations"]);

  // open collapse menu on desktop
  const [dropdownShow, setDropdownShow] = useState(false);

  // open "ALL CATEGORIES" collapse on mobile
  const [open, setOpen] = useState(false);

  const [clothingProductsGroup, setclothingProductsGroup] = useState([]);

  // Set clothingProductsGroup from app context, check if values are ready.
  useEffect(() => {
    if (app.appConfig?.clothingProductsGroup) {
      const sortedClothingProductsGroup = app.appConfig?.clothingProductsGroup.sort((a, b) => a[router.locale].localeCompare(b[router.locale]));
      setclothingProductsGroup(sortedClothingProductsGroup);
    }
  }, [app]);

  // Close menu when changing route
  useEffect(() => {
    setExpanded(false);
  }, [router]);

  return (
    <div className="mb-0">
      <Nav className={`w-100 ${s.all_links}`}>
        {/* ADMIN DESKTOP DROPDOWN  */}
        {auth.isUserAdmin() && (
          <>
            <div className="d-none d-lg-flex align-items-center">
              <Nav.Item onMouseEnter={() => setDropdownShow(true)} onMouseLeave={() => setDropdownShow(false)}>
                <Dropdown id="admin-dropdown" className={`align-middle fw-semibold ${s.navdropdown}`} show={dropdownShow}>
                  <Dropdown.Toggle as={CustomDropdownToggle} bootstrapStyles="fw-semibold py-0" withChevron={true} id="admin-dropdown" variant="menu">
                    {t("management")}
                  </Dropdown.Toggle>
                  <Dropdown.Menu style={{ marginTop: "0rem" }}>
                    <Link href="/products" passHref>
                      <Dropdown.Item className="fw-semibold" eventKey="1">
                        {t("products")}
                      </Dropdown.Item>
                    </Link>
                    <Link href="/kol-la-products" passHref>
                      <Dropdown.Item className="fw-semibold" eventKey="2">
                        {t("kol-la-products")}
                      </Dropdown.Item>
                    </Link>
                    <Link href="/presale-products" passHref>
                      <Dropdown.Item className="fw-semibold" eventKey="3">
                        {t("presale-products")}
                      </Dropdown.Item>
                    </Link>
                    {!auth.isUserEmployee() && (
                      <Link href="/users" passHref>
                        <Dropdown.Item className="fw-semibold" eventKey="4">
                          {t("users")}
                        </Dropdown.Item>
                      </Link>
                    )}
                    <Dropdown.Item className="fw-semibold" eventKey="5">
                      <Link href="/settings" passHref>
                        {t("settings")}
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item className="fw-semibold" eventKey="6">
                      <Link href="/statistiche-ordini" passHref>
                        {t("orders-statistics")}
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item className="fw-semibold" eventKey="7">
                      <Link href="/statistiche-fatture" passHref>
                        {t("invoices-statistics")}
                      </Link>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Nav.Item>
            </div>
            <span className="d-none d-lg-flex align-items-center">|</span>
          </>
        )}
        {/* DESKTOP LINKS FOR CUSTOMERS */}
        <div>
          <div className="d-none d-lg-flex fw-semibold flex-sm-wrap">
            <Link href="/catalogue/ALL_PRODUCTS?Page=1" passHref>
              <Nav.Link className="ps-4 py-3 py-lg-1 px-3">{t("catalogue")}</Nav.Link>
            </Link>
            <span className="d-none d-lg-flex align-items-center">|</span>
            {app.topCategories &&
              app.topCategories.slice(0, 3).map((listItem, key) => (
                <Link key={`topCategoriesDesktop-` + key} href={`/catalogue/${listItem.key.toLowerCase()}?Page=1`} passHref>
                  <Nav.Link className="ps-4 py-3 py-lg-1 px-3">
                    {fallbackTranslation("clothing-product-group", "db-translations", listItem, t, router.locale)}
                  </Nav.Link>
                </Link>
              ))}
            {/* TODO: Enable / disable in admin settings */}
            {/* <Link href="/catalogue/ALL_PRODUCTS?season=SS2024" passHref>
              <Nav.Link className="ps-4 py-3 py-lg-1 px-3">{t("common.collection", { ns: "common" })} SS2024</Nav.Link>
            </Link>
            <Link href="/catalogue/ALL_PRODUCTS?season=FW2024" passHref>
              <Nav.Link className="ps-4 py-3 py-lg-1 px-3">{t("common.collection", { ns: "common" })} FW2024</Nav.Link>
            </Link> */}
            <Link href="/catalogue/DISCOUNT_PRODUCTS?Page=1" passHref>
              <Nav.Link className="ps-4 py-3 py-lg-1 px-3">{t("sale")}</Nav.Link>
            </Link>
            <Link href="/catalogue/NEW_PRODUCTS?Page=1" passHref>
              <Nav.Link className="ps-4 py-3 py-lg-1 px-3">{t("new-arrivals")}</Nav.Link>
            </Link>
            {((auth.isUserAdmin() && app.appConfig?.showReservations?.administrator) ||
              (auth.isUserCustomer() && app.appConfig?.showReservations?.customer)) && (
              <Link href="/reservations/ALL_PRODUCTS?Page=1" passHref>
                <Nav.Link className="ps-4 py-3 py-lg-1 px-3">{t("on-preorder")}</Nav.Link>
              </Link>
            )}
            {((auth.isUserAdmin() && app.appConfig?.showPresales?.administrator) ||
              (auth.isUserCustomer() && app.appConfig?.showPresales?.customer && auth.isUserAllowPresale())) && (
              <Link href="/presales/ALL_PRODUCTS?Page=1" passHref>
                <Nav.Link className="ps-4 py-3 py-lg-1 px-3">{t("on-presale")}</Nav.Link>
              </Link>
            )}
          </div>
          <div className="d-none d-lg-flex justify-content-end position-absolute end-0">
            <SearchBar
              className="px-4 py-2"
              slug={true}
              defaultValue={router.query["Name"] || ""}
              pathName={"catalogue"}
              placeHolder={t("common.search-product", { ns: "common" }) + "..."}
              size="sm"
            />
          </div>
        </div>
        {/* ADMIN MOBILE DROPDOWN  */}
        {auth.isUserAdmin() && (
          <div className="d-lg-none fw-semibold">
            {/* <div className="d-flex pb-3 align-items-center">
                <span className="ps-3 fs-4">Gestione</span>
                <span className="ps-2">
                  <ChevronUpIcon height="28" width="28" color="black" />
                </span>
              </div> */}
            <Link href="/products" passHref>
              <Nav.Link className="ps-4 py-3 fs-5">{t("products")}</Nav.Link>
            </Link>
            <Link href="/kol-la-products" passHref>
              <Nav.Link className="ps-4 py-3 fs-5">{t("kol-la-products")}</Nav.Link>
            </Link>
            <Link href="/presale-products" passHref>
              <Nav.Link className="ps-4 py-3 fs-5">{t("presale-products")}</Nav.Link>
            </Link>
            {!auth.isUserEmployee() && (
              <Link href="/users" passHref>
                <Nav.Link className="ps-4 py-3 fs-5">{t("users")}</Nav.Link>
              </Link>
            )}
            <Link href="/orders" passHref>
              <Nav.Link className="ps-4 py-3 fs-5">{t("orders")}</Nav.Link>
            </Link>
            {((auth.isUserAdmin() && app.appConfig?.showPresales?.administrator) ||
              (auth.isUserCustomer() && app.appConfig?.showPresales?.customer && auth.isUserAllowPresale())) && (
              <Link href="/presale-orders" passHref>
                <Nav.Link className="ps-4 py-3 fs-5">{t("presale-orders")}</Nav.Link>
              </Link>
            )}
            {/* {!auth.isUserEmployee() && auth.isUserAdmin("DOCUMENTS") && (
              <Link href="/documents" passHref>
                <Nav.Link className="ps-4 py-3 fs-5">{t("documents")}</Nav.Link>
              </Link>
            )} */}
            <Link href="/refunds" passHref>
              <Nav.Link className="ps-4 py-3 fs-5">{t("refunds")}</Nav.Link>
            </Link>
            {!auth.isUserEmployee() && (
              <Link href="/admin-invoices" passHref>
                <Nav.Link className="ps-4 py-3 fs-5">{t("invoices")}</Nav.Link>
              </Link>
            )}
            <Link href="/settings" passHref>
              <Nav.Link className="ps-4 py-3 fs-5">{t("settings")}</Nav.Link>
            </Link>
            <Link href="/statistiche-ordini" passHref>
              <Nav.Link className="ps-4 py-3 fs-5">{t("orders-statistics")}</Nav.Link>
            </Link>
            <Link href="/statistiche-fatture" passHref>
              <Nav.Link className="ps-4 py-3 fs-5">{t("invoices-statistics")}</Nav.Link>
            </Link>
          </div>
        )}

        <hr className={`d-lg-none ${s.horizontalline}`} />

        {/* MOBILE LINKS FOR CUSTOMERS */}
        <div className="d-lg-none fw-semibold">
          <Navbar expand="lg" className="px-1" expanded={open}>
            <div className="d-flex ps-2">
              <Navbar.Toggle aria-controls="navbar-nav" onClick={() => setOpen(!open)}>
                <span className="fw-semibold" style={{ color: "black" }}>
                  {t("catalogue")}
                </span>
                {open ? <ChevronUpIcon height="24" width="24" color="black" /> : <ChevronDownIcon height="24" width="24" color="black" />}
              </Navbar.Toggle>
            </div>
            <Navbar.Collapse id="navbar-nav" className="pt-2">
              <Nav onSelect={() => setExpanded(false)} className="fw-semibold">
                <Link href="/catalogue/ALL_PRODUCTS?Page=1" passHref>
                  <Nav.Link className="ps-4 fs-6">{t("all-products")}</Nav.Link>
                </Link>
                {clothingProductsGroup.map(
                  (listItem, i) =>
                    listItem.active && (
                      <Link href={`/catalogue/${listItem.key.toLowerCase()}?Page=1`} passHref key={i}>
                        <Nav.Link className="ps-4">{fallbackTranslation("clothing-product-group", "db-translations", listItem, t, router.locale)}</Nav.Link>
                      </Link>
                    )
                )}
              </Nav>
            </Navbar.Collapse>
          </Navbar>

          <span className="d-none d-lg-flex align-items-center">|</span>
          {app.topCategories &&
            app.topCategories.slice(0, 3).map((listItem, key) => (
              <Link key={`topCategoriesMobile-` + key} href={`/catalogue/${listItem.key.toLowerCase()}?Page=1`} passHref>
                <Nav.Link className="ps-4 py-3 fs-5">{fallbackTranslation("clothing-product-group", "db-translations", listItem, t, router.locale)}</Nav.Link>
              </Link>
            ))}
          {/* TODO: Enable / disable in admin settings */}
          <Link href="/catalogue/ALL_PRODUCTS?season=SS2024" passHref>
            <Nav.Link className="ps-4 py-3 fs-5">{t("common.collection", { ns: "common" })} SS2024</Nav.Link>
          </Link>
          <Link href="/catalogue/ALL_PRODUCTS?season=FW2024" passHref>
            <Nav.Link className="ps-4 py-3 fs-5">{t("common.collection", { ns: "common" })} FW2024</Nav.Link>
          </Link>
          <Link href="/catalogue/DISCOUNT_PRODUCTS?Page=1" passHref>
            <Nav.Link className="ps-4 py-3 fs-5">{t("sale")}</Nav.Link>
          </Link>
          <Link href="/catalogue/ALL_PRODUCTS?orderBy=BEST-SELLERS" passHref>
            <Nav.Link className="ps-4 py-3 fs-5">{t("most-sold")}</Nav.Link>
          </Link>
          {((auth.isUserAdmin() && app.appConfig?.showReservations?.administrator) || (auth.isUserCustomer() && app.appConfig?.showReservations?.customer)) && (
            <Link href="/reservations/ALL_PRODUCTS?Page=1" passHref>
              <Nav.Link className="ps-4 py-3 fs-5">{t("on-preorder")}</Nav.Link>
            </Link>
          )}
          {((auth.isUserAdmin() && app.appConfig?.showPresales?.administrator) ||
            (auth.isUserCustomer() && app.appConfig?.showPresales?.customer && auth.isUserAllowPresale())) && (
            <Link href="/presales/ALL_PRODUCTS?Page=1" passHref>
              <Nav.Link className="ps-4 py-3 fs-5">{t("on-presale")}</Nav.Link>
            </Link>
          )}
        </div>
        <hr className="d-lg-none" style={{ width: "95%" }} />
      </Nav>
    </div>
  );
}
